<template>
  <div class="">
    <div class="py-120px">
      <img
        class="w-100px h-100px mx-auto"
        src="@/assets/images/logo.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="">
      <van-form
        ref="form"
        class="bg-white flex-1"
        @submit="onSubmit"
        :show-error-message="false"
        :error="false"
        @failed="formFailed"
      >
        <van-field
          v-model="form.phone"
          name="phone"
          maxlength="11"
          type="number"
          label="手机号"
          placeholder="请填写手机号"
          :rules="[
            { required: true, message: '请填写手机号' },
            { validator: validatorPhone, message: '请填写正确的手机号' },
          ]"
        />
        <van-field
          class="mt-10px"
          v-model="form.password"
          type="password"
          name="password"
          label="密码"
          placeholder="请填写密码"
          :rules="[
            { required: true, message: '请填写密码' },
            { validator: validatorPwd, message: '密码为6~16位' },
          ]"
        />
        <van-field
          class="mt-10px"
          v-model="form.verify_code"
          name="verify_code"
          label="验证码"
          placeholder="请填写验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <van-button
              color="#969799"
              native-type="button"
              plain
              size="mini"
              @click.prevent="sendSms"
              :disabled="isSmsSend"
            >
              {{ sendBtnText }}
            </van-button>
          </template>
        </van-field>
        <van-field
          class="mt-10px"
          v-model="form.code"
          name="code"
          label="邀请码"
          placeholder="请输入邀请码"
        />
        <div class="mx-16px py-20px flex items-center">
          <van-checkbox v-model="checkbox" shape="square" icon-size="12">
            <div class="text-[#646566] text-12px">已阅读同意并接受</div>
          </van-checkbox>
          <div class="text-12px">
            <a :href="config.user_promotion_agreement" class="text-[#2A82E4]"
              >《用户推广服务协议》</a
            >
            <a :href="config.user_hide_agreement" class="text-[#2A82E4]"
              >《隐私政策》</a
            >
          </div>
        </div>
        <div class="m-16px">
          <van-button
            round
            block
            class="bg-primary text-white"
            native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>

      <div class="m-16px">
        <van-button
          round
          block
          class="bg-primary text-white"
          @click="$router.push({ path: '/download' })"
        >
          下载APP
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
const defaultSeconds = 60
export default {
  data() {
    return {
      bgUrl: '',
      isSmsSend: false,
      sendBtnText: '发送验证码',
      timer: null,
      counter: defaultSeconds,
      checkbox: false,
      config: {
        about_us: '',
        user_hide_agreement: '',
        user_promotion_agreement: '',
      },
      form: {
        phone: '',
        password: '',
        code: '',
        verify_code: '',
      },
    }
  },
  created() {
    const { code } = this.$route.query
    this.form.code = code
    this.ad()
    this.getConfig()
  },
  methods: {
    validatorPwd(val) {
      return val.length >= 6 && val.length <= 16
      // return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(val)
    },
    validatorPhone(val) {
      return /^1[3-9]\d{9}$/.test(val)
      // return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
      //   val
      // )
    },
    formFailed({ errors }) {
      for (let i = 0; i < errors.length; i++) {
        this.$toast(errors[i].message)
        break
      }
    },
    async getConfig() {
      const resData = await this.$api.get('/v1/article-config')
      this.config = resData
    },
    async onSubmit() {
      if (this.checkbox == false)
        return this.$toast('请阅读同意并接受 《用户推广服务协议》 《隐私政策》')
      try {
        await this.$api.post('/v1/register', this.form)
        this.$toast('注册成功')
        setTimeout(() => {
          this.$router.push('/download')
        }, 1000)
      } catch (error) {
        console.log(error)
      }
    },
    async sendSms() {
      this.$refs.form
        .validate(['phone'])
        .then(async () => {
          try {
            await this.$api.post('/v1/sms-codes', {
              phone: this.form.phone,
              type: '1',
            })
            this.isSmsSend = true
            this.countDown()
          } catch (error) {
            console.log(error)
          }
        })
        .catch((err) => {
          for (let i = 0; i < err.length; i++) {
            this.$toast(err[i].message)
            break
          }
        })
    },
    reset() {
      this.isSmsSend = false
      this.sendBtnText = '发送验证码'
      if (this.timer) {
        clearInterval(this.timer)
        this.counter = defaultSeconds
        this.timer = null
      }
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `${this.counter} S`
        this.counter--
        if (this.counter < 0) {
          this.reset()
        }
      }, 1000)
    },
    onPhoneChange() {
      this.reset()
    },
    async ad() {
      const { share_register_banner } = await this.$api.get('/v1/ads', {
        params: {
          keys: 'share_register_banner',
        },
      })
      if (share_register_banner.length)
        this.bgUrl = share_register_banner[0].image
    },
  },
}
</script>
<style>

.van-field--error .van-field__control,
.van-field--error .van-field__control::placeholder {
  color: unset;
}

</style>
<style lang="scss" scoped>

.bg-img {
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.bg-form {
  background: linear-gradient(0deg, #fff 50%, rgba(255, 255, 255, 0) 100%);
}

</style>
